:root {
  --font: 'DINPro', 'sans-serif';
  --color-blue: #29477b;
  --color-orange: #fda033;
  --default-padding: 15vw;
  --gradient: linear-gradient(#647086, #546c8e);

  @media screen and (min-width: 768px) {
    --default-padding: 8vw;
  }
}

/* EASINGS */
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);

@mixin f5 {
  font-size: 5vw;

  @media screen and (min-width: 350px) {
    font-size: 5.4vw;
  }

  @media screen and (min-width: 768px) {
    font-size: 4.5vw;
  }
}