.start-experience {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__text {
    color: white;
    margin-top: var(--default-padding);
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    opacity: 0;

    .word {
      transform: translateY(30px);
      opacity: 0;
      display: inline-block;
    }

    @include f5;
  }

  &__button {
    margin-top: var(--default-padding);

    button { width: 100px; }

    .start {
      position: absolute;
      left: 0;
      top: calc(50% + 20px);
      width: 100%;
      transform: translateY(-50%);
      opacity: 0;

      .char { opacity: 0; }
    }
  }

  &__robot {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: auto;
    font-size: 0;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__background,
  &__trame {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}