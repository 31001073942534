.portrait-mode {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: var(--color-blue);
  font-size: 20px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  @media screen and (orientation:landscape) { display: flex; }

  &__text {
    letter-spacing: 0.5px;
    color: white;
    text-align: center;
    max-width: 80%;
    width: 100%;
  }
}
