.button {
  text-align: center;

  button {
    position: relative;
    overflow: hidden;
    appearance: none;
    background-color: var(--color-blue);
    color: white;
    text-transform: uppercase;
    border: none;
    padding: 10px 25px;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: opacity 0.6s $ease-out;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}