html {
  margin: 0;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font);
  overflow: hidden;
}

.debugger {
  z-index: 1;
  position: absolute;
  right: 25px;
  bottom: 20px;

  &__dot {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: red;

    &.active { background-color: green; }
  }
}

model-viewer{
  display: none;
}

.char {
  display: inline-block;
}