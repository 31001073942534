.scan {
  --bandwidth: 15px;

  &.hide {
    display: none;
  }

  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  &__trame {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__logo {
    padding-bottom: var(--default-padding);
  }

  &__text {
    color: white;
    background: var(--gradient);
    text-align: center;
    text-transform: uppercase;
    margin-left: var(--bandwidth);
    width: calc(100% - 2 * var(--bandwidth));
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px 0;
    opacity: 0;

    .word {
      transform: translateY(30px);
      opacity: 0;
      display: inline-block;
    }

    @include f5;
  }

  &__card {
    position: relative;
    flex: 1;
    width: calc(100% - 2 * var(--bandwidth));
    margin-left: var(--bandwidth);

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__card-inner {
    &.cards {
      .img{
        position: absolute;
        width: 28%;
        height: auto;
        transform: translate(-50%, -50%);

        &:nth-child(1) { z-index: 3; left: 65%; top: 40%; }
        &:nth-child(2) { z-index: 2; left: 50%; top: 50%;  }
        &:nth-child(3) { z-index: 1; left: 35%; top: 60%;  }

        @media screen and (min-width: 768px) {
          width: 25%;

          &:nth-child(1) { left: 62%; }
          &:nth-child(3) { left: 38%;}
        }

        @media screen and (max-height: 600px) {
          width: 25%;

          &:nth-child(1) { left: 62%; }
          &:nth-child(3) { left: 38%;}
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: -6px 9px 5px rgba(0, 0, 0 , 0.5);
          opacity: 0;
        }
      }
    }

    &.merch {
      .img {
        position: absolute;
        width: 50%;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;

        @media screen and (min-width: 768px) { width: 40%; }

        img {
          width: 100%;
          height: auto;
          opacity: 0;
        }
      }
    }
  }

  &__button {
    padding-bottom: 15px;
    margin-bottom: var(--bandwidth);
  }

  &__left {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--bandwidth);
    height: 100%;
  }

  &__right {
    position: absolute;
    right: 0;
    top: 0;
    width: var(--bandwidth);
    height: 100%;
  }

  &__bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--bandwidth);

    img {
      position: absolute;
      bottom: var(--bandwidth);

      &:nth-child(1) { right: var(--bandwidth); }
      &:nth-child(2) {
        transform: scaleX(-1);
        left: var(--bandwidth);
      }
    }
  }

  &__top,
  &__left,
  &__bottom,
  &__right {
    background-color: var(--color-orange);
  }
}