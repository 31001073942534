.end-experience {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-orange);

  &.hide { display: none; }

  &__trame {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    background: linear-gradient(#677ca7 0%, #646F8A 10%, #646F8A 90%, #677ca7 100%);
    text-transform: uppercase;
    color: white;
    width: calc(100% - 30px);
    margin-left: 15px;
    border-radius: 5px;
    text-align: center;
    padding: 40px 0;
    opacity: 0;

    @include f5;

    .text {
      opacity: 0;

      .word {
        transform: translateY(30px);
        opacity: 0;
        display: inline-block;
      }
    }
  }

  &__downloads {
    text-align: center;
    margin-bottom: 10px;

    a {
      display: block;
      margin: 5px 0;
      opacity: 0;
      transform: translateY(15px);
      pointer-events: none;
    }

    img {
      width: 150px;
      max-width: 100%;
    }
  }
}